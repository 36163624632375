@tailwind base;
@tailwind components;
@tailwind utilities;

/* Poppins */
@font-face {
  font-family: 'Poppins';
  src: url('../components/fonts/Poppins/Poppins-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../components/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../components/fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../components/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../components/fonts/Poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

/* Open Sans */
@font-face {
  font-family: 'OpenSans';
  src: url('../components/fonts/OpenSans/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../components/fonts/OpenSans/OpenSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../components/fonts/OpenSans/OpenSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../components/fonts/OpenSans/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../components/fonts/OpenSans/OpenSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
}